import axios from "axios";

class AuthService {

    getToken() {
        return sessionStorage.getItem('token');
    }


    isAuthenticated() {
        if (this.getToken() == null) {
            return false;
        }else{
            return true;
        }
    }

    async login(data: { email: string, password: string }): Promise<Boolean> {
        try{
            let response = await axios.post(process.env.REACT_APP_API_ENDPOINT + 'login', 
                data)


            if (response.data.status == "success") {
                sessionStorage.setItem('token', response.data.token)
                sessionStorage.setItem('user', JSON.stringify(response.data.user))
                return true
            }

            return false
        } catch(e){
            console.log(e)
            return false
        }
    }

    async register(data: { name:string, email: string, password: string }): Promise<Boolean> {
        try{
            let response  = await axios.post(
                process.env.REACT_APP_API_ENDPOINT + 'register', 
                data)

            
            if (response.data.status == "success") {
                return true
            }   

            return false
        } catch(e){
            console.log(e)
            return false
        }

    }



}

export default AuthService;