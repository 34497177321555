import { AnyAaaaRecord } from 'dns';
import React from 'react'
import {useRecoilState} from 'recoil'
import { BookServiceAtom } from '../../../atoms/bookServiceAtom'

export default function TypeOfService() {

    const [bookService, setBookService] = useRecoilState(BookServiceAtom);

    const handleTypeServiceChange = (event: any) => {
        setBookService({...bookService, type_of_service: event.target.value})
    }


    return (
        <div className="mt-6 block p-6  bg-white rounded-lg border border-gray-200 shadow-md  dark:bg-gray-800 dark:border-gray-700">

            <div className="max-w-4xl mx-auto bg-white">
                <h2 className="text-lg mb-2 font-semibold text-gray-700 capitalize dark:text-white">Service</h2>

                <div>
                    <div className=" mt-1">
                        <div>
                            <label className="text-gray-700 dark:text-gray-200" htmlFor="username">Type of Service</label>
                            <select value={bookService.type_of_service} onChange={handleTypeServiceChange} id="bedrooms" placeholder='Type of service' className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring">
                                <option value="babysitters">Quality Baby Sitters</option>
                                <option value="chefs">Quality Chefs</option>
                                <option value="housekeeper">Quality House Keeper</option>
                                <option value="gardener">Quality Gardener</option>
                            </select>
                        </div>

                        {/* <div>
                            <label className="text-gray-700 dark:text-gray-200" htmlFor="emailAddress">Number of Bathrooms</label>
                            <input id="bathrooms" placeholder='Number of bathrooms' type="number" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" />
                        </div> */}


                    </div>
                </div>
            </div>
        </div>
    )
}
