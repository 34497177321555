import {atom } from 'recoil'
import BookServiceType from '../types/bookServiceType';


let default_days: any = {"sunday": false, "monday": false, "tuesday": false, "wednesday": false, "thursday": false, "friday": false, "saturday": false};

export const BookServiceAtom = atom({
    key: 'BookServiceState', // unique ID (with respect to other atoms/selectors)
    default: {
      regularity: "one_time",
      type_of_service: "babysitters",
      regularity_details: "one_time",

    } as BookServiceType // default value (aka initial value)
  });
