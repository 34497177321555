import { useState } from "react";
import { useRecoilState } from "recoil";
import { homeSearchServicesPopupState } from "../../../atoms/searchSiteAtoms";
import { Popup } from "../../../components/serviceSearchPopup";

export const SearchSection = () => {
  const [openPopup, setOpenPopup] = useRecoilState(
    homeSearchServicesPopupState
  );

  const openPopupHandler = () => {
    setOpenPopup(!openPopup);
  };

  return (
    <div>
      <div className="bg-search-site-pattern bg-fixed bg-repeat-round bg-blend-normal">
        <div
          id="searchSection"
          className="flex m-auto flex-col w-full items-center justify-center max-w-2xl px-4 pt-16 pb-32 sm:max-w-xl md:max-w-2xl lg:pt-32 md:px-8"
        >
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <div></div>
            <h2 className="max-w-lg mb-6 text-white font-sans text-3xl font-bold leading-none tracking-tight  sm:text-4xl md:mx-auto">
              We are here, just for you!
            </h2>
            <p className="text-base text-white md:text-lg">
              For all your cleaning and housekeeping needs
            </p>
          </div>

          <div className="flex items-center justify-center w-full">
            <div className="relative inline-block w-full">
              <form
                onClick={openPopupHandler}
                className="flex flex-col items-center w-full mb-4 md:flex-row md:px-16"
              >
                <div className="inline-block relative w-full">
                  <input
                    className="px-4 w-full text-gray-400 py-3 rounded-full"
                    value="Select a Service"
                    readOnly
                  />
                  <svg
                    className=" absolute text-gray-400 right-3 bottom-2 w-8 h-8"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </div>
              </form>

              {openPopup ? <Popup popupHander={openPopupHandler} /> : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/* 
<p className="max-w-md text-xs text-white sm:text-sm md:text-center mb-20">
                     to be removed
                    </p>
*/
