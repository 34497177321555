import React from 'react';
import { Link } from 'react-router-dom';

export default function ApplyHeader() {
  return (
    <>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
        <div className="flex flex-col mb-16 sm:text-center sm:mb-0">
          <a href="/" className="mb-6 sm:mx-auto">
            <div className="flex items-center justify-center w-12 h-12 rounded-full bg-burgundy-300">
            <svg className="text-white w-12 h-12 p-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
            </div>
          </a>
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
              
              Become a Quality Maid today!
            </h2>
            <p className="text-base text-gray-700 md:text-lg">
             Apply to become a maid today and we will connect you with different clients.
             You get to choose when and were you want work.
            </p>
          </div>
          <div>
          <Link className="lg:mt-2 lg:ml-3 xl:mt-0 mt-3 flex-shrink-0 inline-flex text-white bg-burgundy-300 border-0 py-2 px-6 focus:outline-none focus:bg-burgundy-500 hover:bg-burgundy-500 rounded" to="/apply-step-one">
            Get Started
          </Link>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
