import React from 'react';
import Base from '../base';
import ApplyHeader from './components/applyHeader';
import ApplyReason from './components/applyReason';
import ApplyRequirements from './components/applyRequirements';
import ChooseApply from './components/chooseApply';


export default function Apply() {
 
  return (
    <Base>
      <div className="ApplyPage">
          <ApplyHeader />
          <ChooseApply />
          <ApplyReason />
          {/* <ApplyRequirements /> */}
      </div>
    </Base>
  );
}
