import React from "react";
//import { Link } from 'react-router-dom';

export default function WhyChooseUs() {
  return (
    <>
      <section className="text-gray-600  border-t-2 border-gray-100 body-font">
        <div className="container py-10 mt-10">
          <div className="flex flex-col items-center text-center w-full mb-20">
            <h1 className="text-2xl font-medium pb-4 tracking-wide text-gray-800 dark:text-white lg:text-4xl">
              Why choose Quality Maids
            </h1>
            <div className="grid md:grid-cols-3 sm:grid-cols-2 gap-8 mx-auto">
              <div className="">
                <div className="flex rounded-lg w-full shadow-lg h-full bg-gray-50 p-8 flex-col">
                  <div className="flex items-center mb-3">
                    <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-burgundy-300 text-white flex-shrink-0">
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                      </svg>
                    </div>
                    <h2 className="text-gray-900 text-lg title-font font-medium">
                      Realiability
                    </h2>
                  </div>
                  <div className="flex-grow">
                    <p className="leading-relaxed text-base">
                    Our team is a stable crew, and it is our
                      most valuable resource
                    </p>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="flex rounded-lg shadow-lg w-full h-full bg-gray-50 p-8 flex-col">
                  <div className="flex items-center mb-3">
                    <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-burgundy-300 text-white flex-shrink-0">
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                        ></path>
                      </svg>
                    </div>
                    <h2 className="text-gray-900 text-lg title-font font-medium">
                      Affordability
                    </h2>
                  </div>
                  <div className="flex-grow">
                    <p className="leading-relaxed text-base">
                      We offer services for all income groups. Well, everyone
                      deserves a quality maid suitable to their particular needs.
                       </p>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="flex rounded-lg shadow-lg w-full h-full bg-gray-50 p-8 flex-col">
                  <div className="flex items-center mb-3">
                    <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-burgundy-300 text-white flex-shrink-0">
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                        ></path>
                      </svg>
                    </div>
                    <h2 className="text-gray-900 text-lg title-font font-medium">
                      Quick Access
                    </h2>
                  </div>
                  <div className="flex-grow">
                    <p className="leading-relaxed text-base">
                      {/* Ready to serve you! We understand
                      the struggle of finding a suitable helper, and
                      sometimes the current one can just wake up gone and you are
                      all desparate.  */}
                      
                      We are really just a call away to save you from
                      that stress. Services can also be booked through the website
                    </p>
                  </div>
                </div>
              </div>
              
            </div>

            <div className="mt-8 grid md:grid-cols-3 gap-8 mx-auto">
            <div className="">
                <div className="flex rounded-lg shadow-lg h-full bg-gray-50 p-8 flex-col">
                  <div className="flex items-center mb-3">
                    <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-burgundy-300 text-white flex-shrink-0">
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                        ></path>
                      </svg>
                    </div>
                    <h2 className="text-gray-900 text-lg title-font font-medium">
                      Continuous Improvement
                    </h2>
                  </div>
                  <div className="flex-grow">
                    <p className="leading-relaxed text-base">
                      We are committed to excellence and we tailor make our services 
                      to the needs of customers
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <p className=" tracking-tight hover:tracking-wide indent-24 text-lg mt-10 whitespace-normal leading-relaxed text-justify">
              Quality Maids provides a variety of housekeeping and cleaning
              services. Choose Us because of our commitment to excellence. We
              have services that fit all your needs and unlike other companies,
              we do not charge an agency fee on top of hourly rates or tie you
              into long contracts. We like it simple, fair, flat-fee for an
              exceptional service. Our cleaning housekeeping and training
              services are affordable and our team consist of experts and highly
              trained personnel. If for any reason you are not happy with our
              services, we will come back and clean the specific areas that did
              not meet your expecations. Nothing is more important than your
              satisfaction.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
// eslint-disable-next-line no-lone-blocks
{
  /*
            <div className="flex flex-wrap -m-4">
                <div className="p-4 md:w-1/3">
                    <div className="flex rounded-lg shadow-lg h-full bg-gray-50 p-8 flex-col">
                        <div className="flex items-center mb-3">
                            <div
                                className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-burgundy-300 text-white flex-shrink-0">
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                            </div>
                            <h2 className="text-gray-900 text-lg title-font font-medium">Convenient</h2>
                        </div>
                        <div className="flex-grow">
                            <p className="leading-relaxed text-base">Donec ultricies eros sed diam efficitur, id fringilla nisl semper</p>
                           
                        </div>
                    </div>
                </div>
                <div className="p-4 md:w-1/3">
                    <div className="flex rounded-lg shadow-lg h-full bg-gray-50 p-8 flex-col">
                        <div className="flex items-center mb-3">
                            <div
                                className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-burgundy-300 text-white flex-shrink-0">
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path></svg>
                            </div>
                            <h2 className="text-gray-900 text-lg title-font font-medium">Reliable</h2>
                        </div>
                        <div className="flex-grow">
                            <p className="leading-relaxed text-base">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                            
                        </div>
                    </div>
                </div>
                <div className="p-4 md:w-1/3">
                    <div className="flex rounded-lg shadow-lg h-full bg-gray-50 p-8 flex-col">
                        <div className="flex items-center mb-3">
                            <div
                                className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-burgundy-300 text-white flex-shrink-0">
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>
                            </div>
                            <h2 className="text-gray-900 text-lg title-font font-medium">Flexible
                            </h2>
                        </div>
                        <div className="flex-grow">
                            <p className="leading-relaxed text-base">Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
                            
                        </div>
                    </div>
                </div>
                <div className="p-4 md:w-1/3">
                    <div className="flex rounded-lg shadow-lg h-full bg-gray-50 p-8 flex-col">
                        <div className="flex items-center mb-3">
                            <div
                                className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-burgundy-300 text-white flex-shrink-0">
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"></path></svg>
                            </div>
                            <h2 className="text-gray-900 text-lg title-font font-medium">Dignified Work Opportunies
                            </h2>
                        </div>
                        <div className="flex-grow">
                            <p className="leading-relaxed text-base">Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
                            
                        </div>
                    </div>
                </div>
                <div className="p-4 md:w-1/3">
                    <div className="flex rounded-lg shadow-lg h-full bg-gray-50 p-8 flex-col">
                        <div className="flex items-center mb-3">
                            <div
                                className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-burgundy-300 text-white flex-shrink-0">
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"></path></svg>
                            </div>
                            <h2 className="text-gray-900 text-lg title-font font-medium">Support
                            </h2>
                        </div>
                        <div className="flex-grow">
                            <p className="leading-relaxed text-base">Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
                            
                        </div>
                    </div>
                </div>
                <div className="p-4 md:w-1/3">
                    <div className="flex rounded-lg shadow-lg h-full bg-gray-50 p-8 flex-col">
                        <div className="flex items-center mb-3">
                            <div
                                className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-burgundy-300 text-white flex-shrink-0">
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"></path></svg>
                            </div>
                            <h2 className="text-gray-900 text-lg title-font font-medium">Secure
                            </h2>
                        </div>
                        <div className="flex-grow">
                            <p className="leading-relaxed text-base">Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
                            
                        </div>
                    </div>
                </div>
            </div>*/
}
