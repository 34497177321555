import React from 'react'
import Base from '../base'
import Products from './components/products'





export default function Shop() {
    return (
        <Base>
            <Products />
        </Base>
    )
}
