import React, {useState} from 'react';
import BookButton from './bookButton';
import {useRecoilState} from 'recoil';
import {BookServiceAtom} from '../../../atoms/bookServiceAtom';

export default function WorkTime() {

  const [bookService, setBookService] = useRecoilState(BookServiceAtom);

  const handleRegularityChange = (event: any) => {
    setBookService({ ...bookService, regularity: event.target.value });
  }

  return (
    <>
      <div className="mt-6 block p-6  bg-white rounded-lg border border-gray-200 shadow-md  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">

        <div className=" w-full sm:w-auto overflow-hidden bg-white rounded-lg dark:bg-gray-800 dark:border-gray-700">

          <h2 className="text-lg mb-2 font-semibold text-gray-700 capitalize dark:text-white">How Often</h2>
          <div className=" ">

          <div>
                            <label className="text-gray-700 dark:text-gray-200" htmlFor="username">Regularity</label>
                            <select onChange={handleRegularityChange} id="bedrooms" placeholder='Regular' className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring">
                                <option value="one_time">One Time</option>
                                <option value="regular">Regular</option>
                                
                            </select>
                        </div>
          </div>


          {bookService.regularity == "one_time" ? <OneTime /> : null }
         
          {bookService.regularity == "regular" ? <Regular /> : null }
          
        </div>
      </div>
    </>
  );
}


function OneTime() {

  const [bookService, setBookService] = useRecoilState(BookServiceAtom);

  const handleTimeChange = (event: any) => {
    setBookService({ ...bookService, time: event.target.value });
  }

  const handleDateChange = (event: any) => {
    setBookService({ ...bookService, date: event.target.value });
  }


  return (
    <>
      <div className='mt-4'>

      <h2 className="text-xs font-semibold text-gray-700 uppercase dark:text-white">Date and Time</h2>
        <div className="grid grid-cols-1 gap-6 mt-1 sm:grid-cols-2">
          <div>
            <label className="text-gray-700 dark:text-gray-200" htmlFor="username">Day</label>
            <input id="day" onChange={handleDateChange} placeholder='Day' type="date" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" />
          </div>

          <div>
            <label className="text-gray-700 dark:text-gray-200" htmlFor="emailAddress">Time</label>
            <input id="time" onChange={handleTimeChange} placeholder='Time' type="time" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" />
          </div>


        </div>
      </div>
    </>
  );
}

function Regular() {

  const [bookService, setBookService] = useRecoilState(BookServiceAtom);

  const handleTimeChange = (event: any) => {
    setBookService({ ...bookService, time: event.target.value });
  }

  const handleDateChange = (event: any) => {
    setBookService({ ...bookService, date: event.target.value });
  }

  const handleWorkDaysChange = (event: any, day: string) => {
    let dayValue = event.target.checked;
    let newWorkDays:any = bookService.days;
    newWorkDays = {
      ...newWorkDays,
      [day]: dayValue
    }



    setBookService({ ...bookService, days: newWorkDays });

  }

  const handleRegularityDetailChange = (event: any, regularityOption: string) => {
    let detailValue = event.target.checked;
    let newRegularityDetails:any = bookService.regularity_details;
    newRegularityDetails ={
      ...newRegularityDetails,
      [regularityOption]: detailValue
    }
    

    setBookService({ ...bookService, regularity_details: newRegularityDetails });

  }



  return (
    <>
      <div className="mt-4">
        <div>
          <h2 className="text-xs uppercase font-semibold text-gray-700 dark:text-white">Regularity</h2>


          <div className="grid md:grid-cols-4">
            <div><input onChange={(event) => handleRegularityDetailChange(event, "every_week")} type="checkbox" className="rounded text-burgundy-300 focus:ring-burgundy-300 focus:border-burgundy-300" /> Every Week <br /></div>
            <div><input onChange={(event) => handleRegularityDetailChange(event, "every_2_weeks")} type="checkbox" className="rounded text-burgundy-300 focus:ring-burgundy-300 focus:border-burgundy-300" /> Every 2 Weeks<br /></div>
            <div><input onChange={(event) => handleRegularityDetailChange(event, "every_3_weeks")} type="checkbox" className="rounded text-burgundy-300 focus:ring-burgundy-300 focus:border-burgundy-300" /> Every 3 Weeks <br /></div>
            <div><input onChange={(event) => handleRegularityDetailChange(event, "every_4_weeks")} type="checkbox" className="rounded text-burgundy-300 focus:ring-burgundy-300 focus:border-burgundy-300" /> Every 4 Weeks <br /></div>

          </div>

          <div>
            <div className="mt-6">
              <div>
                <h2 className="text-xs font-semibold text-gray-700 uppercase dark:text-white">Days</h2>


                <div className="grid md:grid-cols-4 grid-cols-2">
                  <div><input onChange={(event) => handleWorkDaysChange(event, "monday")} type="checkbox" className="rounded text-burgundy-300 focus:ring-burgundy-300 focus:border-burgundy-300" /> Monday <br /></div>
                  <div><input onChange={(event) => handleWorkDaysChange(event, "tuesday")} type="checkbox" className="rounded text-burgundy-300 focus:ring-burgundy-300 focus:border-burgundy-300" /> Tuesday <br /></div>
                  <div><input onChange={(event) => handleWorkDaysChange(event, "wednesday")} type="checkbox" className="rounded text-burgundy-300 focus:ring-burgundy-300 focus:border-burgundy-300" /> Wednesday <br /></div>
                  <div><input onChange={(event) => handleWorkDaysChange(event, "thursday")} type="checkbox" className="rounded text-burgundy-300 focus:ring-burgundy-300 focus:border-burgundy-300" /> Thursday <br /></div>
                  <div><input onChange={(event) => handleWorkDaysChange(event, "friday")} type="checkbox" className="rounded text-burgundy-300 focus:ring-burgundy-300 focus:border-burgundy-300" /> Friday <br /></div>
                  <div><input onChange={(event) => handleWorkDaysChange(event, "saturday")} type="checkbox" className="rounded text-burgundy-300 focus:ring-burgundy-300 focus:border-burgundy-300" /> Saturday <br /></div>
                  <div><input onChange={(event) => handleWorkDaysChange(event, "sunday")} type="checkbox" className="rounded text-burgundy-300 focus:ring-burgundy-300 focus:border-burgundy-300" /> Sunday <br /></div>
                </div>
              </div>
            </div>
          </div>

          <div className='mt-6'>

      <h2 className="text-xs font-semibold text-gray-700 uppercase dark:text-white">Date and Time</h2>
        <div className="grid grid-cols-1 gap-6 mt-1 sm:grid-cols-2">
          <div>
            <label className="text-gray-700 dark:text-gray-200" htmlFor="username">Day</label>
            <input id="day" onChange={handleDateChange} placeholder='Day' type="date" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" />
          </div>

          <div>
            <label className="text-gray-700 dark:text-gray-200" htmlFor="emailAddress">Time</label>
            <input id="time" onChange={handleTimeChange} placeholder='Time' type="time" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" />
          </div>


        </div>
      </div>
        </div>
      </div>
    </>
  );
}


