import React, { useEffect } from 'react';
import Base from '../base';
import IndoorWork from './components/typeOfService';
import IndoorExtras from './components/indoorExtras';
import WorkTime from './components/workTime';
import TypeOfService from './components/typeOfService';
import UserDetails from './components/userDetails';
import AuthService from '../../services/auth';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { BookServiceAtom } from '../../atoms/bookServiceAtom';
import { Link } from 'react-router-dom';
import { getCSRFToken } from '../../utils/helpers';
import axios from 'axios';
import { useSnackbar} from 'react-simple-snackbar'
import { snackbarOptions } from '../../utils/snackbarOptions';




export default function BookService() {

    const history = useHistory();
    const authService = new AuthService();
    const [bookService ] = useRecoilState(BookServiceAtom);
    const [openSnackbar, closeSnackbar] = useSnackbar(snackbarOptions)

    useEffect(() => {
        if (!authService.isAuthenticated()) {
            localStorage.setItem('redirectTo', '/book-a-service');
            history.push('/login')
        }
    }, [])

    const processKey = (key:string) => {
        // remove underscores
        key = key.replace(/_/g, ' ');

        // capitalize all first letters
        key = key.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
        })

        return key;
    }




    const handleBookService = async () => {
        let data:any = {}


        // Do some basic validation
        let dataKeys = Object.keys(bookService);

        if (dataKeys.length < 8) {
            openSnackbar('Please fill in all the fields')
            return;
        }

        

        data.user_name = bookService.user_name;
        data.user_email = bookService.user_email;
        data.user_phone = bookService.user_phone;
        data.regularity = bookService.regularity;
        data.regularity_details = bookService.regularity_details;
        data.type_of_service = bookService.type_of_service;
        data.time = bookService.time;
        data.date = bookService.date;


        // Add extras
        let oldExtras:any = bookService.extras
        let extrasKeys = Object.keys(bookService.extras);
        let extrasList:string[] = [];

        if (extrasKeys.length > 0) {
            extrasKeys.forEach((key:string) => {
                if (oldExtras[key]) {
                    extrasList.push(key);
                }
            })
    
            data.extras = extrasList.join(", ");
        }else{
            data.extras = 'No extras selected';
        }

        // Add days
        let oldDays:any = bookService.days
        let extraDaysKeys:any[] =[]

        if (oldDays != null || oldDays != undefined) {
            extraDaysKeys = Object.keys(oldDays);
        }

        if (extraDaysKeys.length > 0) {
            let daysList:string[] = [];

        extraDaysKeys.forEach((key:string) => {
            if (oldDays[key]) {
                daysList.push(key);
            }
        })

        data.days = daysList.join(", ");
    }else{
        data.days = "Check time and date"
    }


        // Add regularity details
        if (bookService.regularity != "one_time"){
            let oldRegularityDetails:any = bookService.regularity_details
            let regularityDetailsKeys:any[] =[]

            if (oldRegularityDetails != null || oldRegularityDetails != undefined) {
                regularityDetailsKeys = Object.keys(oldRegularityDetails);
            }


            let regularityDetailsList:string[] = [];

            regularityDetailsKeys.forEach((key:string) => {
                if (oldRegularityDetails[key]) {
                    regularityDetailsList.push(key);
                }
            })

            data.regularity_details = regularityDetailsList.join(", ");
        }else{
            data.regularity_details = "No details"
        }

        
            


        // Send data to server
        await getCSRFToken()

        axios.post(
            process.env.REACT_APP_API_ENDPOINT + 'tbl/bookings/create', 
            data)
          .then(function (response) {

            if (response.data.status == "success") {
                openSnackbar('Booking done successfully!')

                setTimeout( () => {
                    history.push('/')
                }, 3000)
            }
          })
          .catch(function (error) {
            console.log(error);
          });



    }

    
    return (
        <Base>
            <div className='px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20'>
                <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl">

                    Book Service
                </h2>

                <div className="md:flex">

                    <div className="md:w-2/3 py-3">
                        {/* <Rooms /> */}
                        <TypeOfService />
                        <IndoorExtras />
                        <WorkTime />
                        <UserDetails/>
                        {/* <Insurance /> */}

                        <div className="flex mt-4">
                        <button onClick={handleBookService} className="lg:mt-2 flex justify-center xl:mt-0 mt-3 flex-shrink-0  text-gray-100 bg-burgundy-300 border-0 py-2 px-6 focus:outline-none hover:bg-burgundy-500 hover:text-gray-50 hover:shadow-md rounded">Book Service</button>
                        <Link to="/" className="ml-3 lg:mt-2 flex justify-center xl:mt-0 mt-3 flex-shrink-0  text-black bg-gray-300 border-0 py-2 px-6 focus:outline-none hover:text-burgundy-500  hover:shadow-md rounded">Cancel</Link>

                       
                        </div>
                    </div>
                    {/* <div className='md:w-1/3  rounded-md'>
                        <TotalPrice />
                    </div> */}

                </div>
            </div>




        </Base>
    );
}
