//import liraries
import React, { Component } from "react";
import { Link } from "react-router-dom";
import AppLogo from "./appLogo";
import { useRecoilState } from "recoil";
import { headerServicesPopupState } from "../atoms/searchSiteAtoms";
import { HeaderServicePopup } from "./headerServicePopup";

// create a component
const Header = () => {
  const [openPopup, setOpenPopup] = useRecoilState(headerServicesPopupState);

  const openPopupHandler = () => {
    // setOpenPopup(!openPopup);
  };

  return (
    <div className="Header bg-gray-100">
      <header className="text-gray-800 body-font">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <a className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
            {/* <AppLogo /> */}
            <img style={{ width: "10rem" }} src={ process.env.PUBLIC_URL + "/images/longlogo.png"} alt="" srcSet="" />
            {/* <span className="ml-3 text-xl text-burgundy-300">
              Quality Maids
            </span> */}
          </a>
          <nav className="md:ml-auto md:mr-auto flex flex-wrap items-center text-base justify-center">
            <Link
              to="/"
              className="mr-5 hover:border-b-2 text-gray-800 hover:border-burgundy-500 hover:text-burgundy-300 px-3"
            >
              Home
            </Link>
            <Link
              to="/about"
              className="mr-5 hover:border-b-2 text-gray-800 hover:border-burgundy-500 hover:text-burgundy-300 px-3"
            >
              About
            </Link>
            <Link
              to="/contact"
              className="mr-5 hover:border-b-2 text-gray-800 hover:border-burgundy-500 hover:text-burgundy-300 px-3"
            >
              Contact
            </Link>
            <Link
              to="/services"
              className="mr-5 hover:border-b-2 text-gray-800 hover:border-burgundy-500 hover:text-burgundy-300 px-3"
            >
              Our Services
            </Link>
            <Link
              to="/login"
              className="mr-5 hover:border-b-2 text-gray-800 hover:border-burgundy-500 hover:text-burgundy-300 px-3"
            >
              SignIn
            </Link>
          </nav>

          <Link
            onClick={openPopupHandler}
            className="inline-flex items-center bg-burgundy-300 py-2 px-3 focus:outline-none hover:bg-burgundy-300  rounded text-gray-100 mt-4 md:mt-0"
            to="/book-a-service"
          >
            Book A Service
            {/* <svg
              className="w-6 h-6 ml-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg> */}
          </Link>

          {openPopup ? (
            <HeaderServicePopup popupHander={openPopupHandler} />
          ) : null}
        </div>
      </header>
    </div>
  );
};

export default Header;
