import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Base from '../base';
import { useForm, SubmitHandler } from "react-hook-form";
import StepOneInput from '../../types/stepOne';
import { useRecoilState } from 'recoil';
import { StepOneState } from '../../atoms/stepOneAtom';
import { snackbarOptions } from '../../utils/snackbarOptions';
import { useSnackbar } from 'react-simple-snackbar'
import AuthService from '../../services/auth';



export default function ApplyStepOne() {

    const history = useHistory();
    const authService = new AuthService();
    const [openSnackbar, closeSnackbar] = useSnackbar(snackbarOptions)
    const [stepOne, setStepOne] = useRecoilState(StepOneState);
    const { register, handleSubmit, watch, formState: { errors } } = useForm<StepOneInput>();
   
    const onSubmit: SubmitHandler<StepOneInput> = data => {
        setStepOne(data);
        openSnackbar('Details saved successfully!');
        history.push('/apply-step-two')
    }


    useEffect(() => {
        if (!authService.isAuthenticated()) {
            localStorage.setItem('redirectTo', '/apply-step-one');
            history.push('/login')
        }
    }, [])

    
 
  return (
    <Base>
      <div className="ApplyStepOne">

    <div className=" my-12 mb-24 max-w-md  m-auto bg-white rounded-md shadow-md p-5 dark:bg-gray-800">
        <h1 className="text-3xl font-semibold text-gray-700 dark:text-white">Step 1: Personal Information</h1>

        <form onSubmit={handleSubmit(onSubmit)} className="mt-6">
            
            <div>
                <label htmlFor="full-name" className="block text-sm text-gray-800 dark:text-gray-200">Full Name</label>
                <input type="text"
                    {...register("name")}
                    placeholder="Full Name e.g Susan More"
                    className="focus:ring-burgundy-200 focus:border-burgundy-400 focus:ring-burgundy-200 focus:border-burgundy-400 shadow border-gray-300 block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300 w-full rounded-md"/>
            </div>
            <div className="mt-4">
                <label htmlFor="phone-number" className="block text-sm text-gray-800 dark:text-gray-200">Phone Number</label>
                <input type="text"
                {...register("phone")}
                placeholder="Phone Number e.g 0778526372"
                    className="focus:ring-burgundy-200 focus:border-burgundy-400 shadow border-gray-300 block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300 w-full rounded-md"/>
            </div>
            <div className="mt-4">
                <label htmlFor="gender" className="block text-sm text-gray-800 dark:text-gray-200">Gender</label>
                <select
                   {...register("gender")}
                    className="focus:ring-burgundy-200 focus:border-burgundy-400 shadow border-gray-300 block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300 w-full rounded-md">
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="rather_not_say">Rather Not Say</option>
                </select>
            </div>

            <div className="mt-4">
                <label htmlFor="dob" className="block text-sm text-gray-800 dark:text-gray-200">Date Of Birth</label>
                <input type="date"
                {...register("dob")}
                    placeholder="Date of Birth"
                    className="focus:ring-burgundy-200 focus:border-burgundy-400 shadow border-gray-300 block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300 w-full rounded-md"/>
            </div>
            <div className="mt-4">
                <label htmlFor="id-number" className="block text-sm text-gray-800 dark:text-gray-200">ID Number</label>
                <input type="text"
                {...register("id_number")}
                    placeholder="ID Number"
                    className="focus:ring-burgundy-200 focus:border-burgundy-400 shadow border-gray-300 block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300 w-full rounded-md"/>
            </div>
            <div className="mt-4">
                <label htmlFor="street-address" className="block text-sm text-gray-800 dark:text-gray-200">Street Address</label>
                <input type="text"
                {...register("address")}
                     placeholder="Street Address"
                    className="focus:ring-burgundy-200 focus:border-burgundy-400 shadow border-gray-300 block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300 w-full rounded-md"/>
            </div>
            <div className="mt-4">
                <label htmlFor="town" className="block text-sm text-gray-800 dark:text-gray-200">Town</label>
                <input type="text"
                {...register("town")}
                   placeholder="Town"
                    className="focus:ring-burgundy-200 focus:border-burgundy-400 shadow border-gray-300 block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300 w-full rounded-md"/>
            </div>
            <div className="mt-4">
                <label htmlFor="province" className="block text-sm text-gray-800 dark:text-gray-200">Province</label>
                <input type="text"
                {...register("province")}
                    placeholder="Province"
                    className="focus:ring-burgundy-200 focus:border-burgundy-400 shadow border-gray-300 block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300 w-full rounded-md"/>
            </div>
            <div className="mt-4">
                <label htmlFor="country" className="block text-sm text-gray-800 dark:text-gray-200">Country</label>
                <input type="text"
                {...register("country")}
                placeholder="Country"
                    className="focus:ring-burgundy-200 focus:border-burgundy-400 shadow border-gray-300 block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300 w-full rounded-md"/>
            </div>

            

            <div className="mt-6">
                
                <button
                    className="w-full px-4 py-2 tracking-wide  text-white transition-colors duration-200 transform bg-burgundy-300 rounded-md hover:bg-burgundy-500 focus:outline-none focus:bg-burgundy-500">
                    Next Step
                </button>
            
            </div>
        </form>

        {/* <div className="flex items-center justify-between mt-4">
            <span className="w-1/5 border-b dark:border-gray-600 lg:w-1/5"></span>

            <a href="#" className="text-xs text-center text-gray-500 uppercase dark:text-gray-400 hover:underline">or login with Social Media</a>

            <span className="w-1/5 border-b dark:border-gray-400 lg:w-1/5"></span>
        </div>

        <div className="flex items-center mt-6 -mx-2">
            <button type="button"
                className="flex items-center justify-center w-full px-6 py-2 mx-2 text-sm font-medium text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:bg-blue-400 focus:outline-none">
                <svg className="w-4 h-4 mx-2 fill-current" viewBox="0 0 24 24">
                    <path
                        d="M12.24 10.285V14.4h6.806c-.275 1.765-2.056 5.174-6.806 5.174-4.095 0-7.439-3.389-7.439-7.574s3.345-7.574 7.439-7.574c2.33 0 3.891.989 4.785 1.849l3.254-3.138C18.189 1.186 15.479 0 12.24 0c-6.635 0-12 5.365-12 12s5.365 12 12 12c6.926 0 11.52-4.869 11.52-11.726 0-.788-.085-1.39-.189-1.989H12.24z">
                    </path>
                </svg>

                <span className="hidden mx-2 sm:inline">Sign in with Google</span>
            </button>

            <a href="#"
                className="p-2 mx-2 text-sm font-medium text-gray-500 transition-colors duration-200 transform bg-gray-300 rounded-md hover:bg-gray-200">
                <svg className="w-5 h-5 fill-current" viewBox="0 0 24 24">
                    <path
                        d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z">
                    </path>
                </svg>
            </a>
        </div> */}

        {/* <p className="mt-8 text-xs font-light text-center text-gray-400"> Already have an account? <Link to="/login"
                className="font-medium text-gray-700 dark:text-gray-200 hover:underline">Login</Link></p> */}
    </div>
      </div>
    </Base>
  );
}