import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import AppLogo from './appLogo'
import axios from 'axios'
import { useSnackbar } from 'react-simple-snackbar'

export default function Footer() {

  const [email, setEmail] = useState("")
  const [openSnackbar, closeSnackbar] = useSnackbar({
    position: 'bottom-left',
  })


  const handleSubscribe = (e: any) => {
    

      axios.post(process.env.REACT_APP_API_ENDPOINT + 'tbl/subscriptions/create', 
      {
        email: email,
        news: true,
        offers: true,
        jobs: true,
      }).then(res => {
        let respData = res.data
        if(respData.status === "success") {
          openSnackbar("Subscribed successfully!")   
        }
      }) 

  }



  


  let currentYear = new Date().getFullYear()

    return (
        <div>
          

            <footer className="text-gray-700 bg-gray-100 body-font">
    <div className="container px-5 py-24 mx-auto">
      <div className="flex flex-wrap md:text-left text-center order-first">
        <div className="lg:w-1/4 md:w-1/2   w-full px-4">
          <h2 className="title-font font-medium text-gray-700 tracking-widest text-sm mb-3">LINKS</h2>
          <nav className="list-none mb-10">
      
            <li>
              <Link to='/' className="text-gray-700 hover:border-burgundy-300 hover:text-burgundy-500">Home</Link>
            </li>
            <li>
              <Link to="/about#about" className="text-gray-700 hover:border-burgundy-300 hover:text-burgundy-500">About</Link>
            </li>
            <li>
              <Link to="/contact" className="text-gray-700 hover:border-burgundy-300 hover:text-burgundy-500">Contact</Link>
            </li>
            
            {/*<li>
              <Link to="/terms" className="text-gray-700 hover:border-burgundy-300 hover:text-burgundy-500">Terms and Conditions</Link>
            </li>*/}
            <li>
              <Link to="/privacy" className="text-gray-700 hover:border-burgundy-300 hover:text-burgundy-500">Privacy Policy</Link>
            </li>
          </nav>
        </div>
        <div className="lg:w-1/4 md:w-1/2 w-full   px-4">
          <h2 className="title-font font-medium text-gray-700 tracking-widest text-sm mb-3">MORE</h2>
          <nav className="list-none mb-10">
            <li>
              <Link to="/apply" className="text-gray-700 hover:border-burgundy-300 hover:text-burgundy-500">Apply to be a Quality Maid</Link>
            </li>
            <li>
              <Link to="/book-a-service" className="text-gray-700 hover:border-burgundy-300 hover:text-burgundy-500">Book A Service</Link>
            </li>
      
          </nav>
        </div>
        {/* <div className="lg:w-1/4 md:w-1/2 w-full px-4">
          <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">CATEGORIES</h2>
          <nav className="list-none mb-10">
            <li>
              <a className="text-gray-600 hover:text-gray-800">First Link</a>
            </li>
            <li>
              <a className="text-gray-600 hover:text-gray-800">Second Link</a>
            </li>
            <li>
              <a className="text-gray-600 hover:text-gray-800">Third Link</a>
            </li>
            <li>
              <a className="text-gray-600 hover:text-gray-800">Fourth Link</a>
            </li>
          </nav>
        </div> */}
        <div className="lg:w-2/4 md:w-1/2 w-full px-4">
          <h2 className="title-font font-medium text-gray-700 tracking-widest text-sm mb-3">SUBSCRIBE</h2>
          <div className="flex xl:flex-nowrap md:flex-nowrap lg:flex-wrap flex-wrap justify-center items-end md:justify-start">
            <div className="relative w-40 sm:w-auto xl:mr-4 lg:mr-0 sm:mr-4 mr-2">
              <label  className="leading-7 text-sm text-gray-700">Email</label>
              <input value={email} onChange={(event) => setEmail(event.target.value)} type="text" placeholder="Email Address" id="footer-field" name="footer-field" className="focus:ring-burgundy-200 focus:border-burgundy-500 shadow border-gray-300 block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300 w-full rounded-md"/>
            </div> 
            
          </div>
          <button onClick={handleSubscribe} className="lg:mt-2 w-48 xl:mt-0 mt-3 flex-shrink-0 inline-flex justify-center text-gray-100 bg-burgundy-300 border-0 py-2 px-6 focus:outline-none hover:bg-burgundy-500 hover:text-gray-50 hover:shadow-md rounded">Subscribe</button>
          <p className="text-gray-700 text-sm mt-2 md:text-left text-center">Recieve offers, news and tips
            <br className="lg:block hidden"/>in your inbox
          </p>
        </div>
      </div>
    </div>
    <div className="bg-gray-800">
      <div className="container px-5 py-6 mx-auto flex items-center sm:flex-row flex-col">
        <a className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
          {/* <AppLogo /> */}
          <img style={{ width: "10rem" }} src={ process.env.PUBLIC_URL + "/images/longlogo.png"} alt="" srcSet="" />
          {/* <span className="ml-3 text-burgundy-300 text-xl">Quality Maids</span> */}
        </a>
        <p className="text-sm text-white sm:ml-6 sm:mt-0 mt-4">© { currentYear }  Maids Private Limited —
          {/* <a href="https://blckmedia.co.zw" rel="noopener noreferrer" className="text-gray-600 ml-1" target="_blank"></a> */}
        </p>
        <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
          <a href="https://www.facebook.com/183574685636580/" target="_blank" rel="noreferrer" className="text-white">
            <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
              <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
            </svg>
          </a>
          {/*<a href="https://twitter.com/Bamboopreneur" target="_blank" rel="noreferrer" className="ml-3 text-white">
            <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
              <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
            </svg>
          </a>*/}
          <a href='https://instagram.com/quality_maids_international?utm_medium=copy_link'className="ml-3 text-white">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
              <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
              <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
            </svg>
          </a>
          {/*<a className="ml-3 text-white">
            <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" className="w-5 h-5" viewBox="0 0 24 24">
              <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
              <circle cx="4" cy="4" r="2" stroke="none"></circle>
            </svg>
          </a>*/}
        </span>
      </div>
    </div>
  </footer>

            
        </div>
    )
}
