import React from "react";

export default function AboutSite() {
  return (
    <>
      <div className="bg-gray-50">
        <div className=" px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
              <span className="relative inline-block">
                <svg
                  viewBox="0 0 52 24"
                  fill="currentColor"
                  className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                >
                  <defs>
                    <pattern
                      id="3071a3ad-db4a-4cbe-ac9a-47850b69e4b7"
                      x="0"
                      y="0"
                      width=".135"
                      height=".30"
                    >
                      <circle cx="1" cy="1" r=".7"></circle>
                    </pattern>
                  </defs>
                </svg>
              </span>
              About Us
            </h2>
            <p className="text-base text-gray-700 md:text-lg"></p>
            <div>
              <img
                className="object-cover w-fullh-150 col-span-2 mr-2 rounded shadow-lg"
                src="https://media.istockphoto.com/photos/happy-woman-cleaning-furniture-with-napkin-picture-id1150345544?k=20&m=1150345544&s=612x612&w=0&h=0MviUCA6dbCTt4a-8omoZOPxzboWEyQU1vVT6iiNXqk="
                alt="img"
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <p className=" m-10 tracking-tight hover:tracking-wide indent-24 text-lg mt-4 whitespace-normal leading-relaxed text-justify">
          Founded in 2018, Quality Maids is committed to excellence in the
          provision of residential and commercial housekeeping services through
          out the nation.We are built on the foundation of being a solution
          provider to the struggle of finding a reliable and trust-worth maid,
          beyond that; we think of ourselves as an answer to all housekeeping
          needs, from laundry to baby sitting and even making special meals. We
          have also developed into providing office- cleaning and janitorial
          services.We aim on becoming your one stop for any cleaning and
          housekeeping needs.Finally, we also offer housekeeping training
          services, Well if you ever think of doing the work yourself, want your
          helper to be better or oops you are getting married soon and could use
          housekeeping skills especially cooking. We are here just for you!
        </p>
      </div>
      <div className="m-10 tracking-tight hover:tracking-wide indent-24 text-lg mt-4 whitespace-normal leading-relaxed text-justify">
        <h2 className="font-bold">Mission</h2>
        <p>
          Our mission is provide consistence quality to our customers in
          housecleaning, office and training services while providing a secure
          constructive work environment for our employees
        </p>
      </div>
    </>
  );
}
