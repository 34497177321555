import axios from "axios";



export const getCSRFToken = async () => {

    let resp = await axios.get(process.env.REACT_APP_SITE_ENDPOINT + "sanctum/csrf-cookie")
        
        if (resp.data.status == "success"){
            // localStorage.setItem('csrfToken', resp.data.token);
            return resp.data.token
        }

        return ""
}