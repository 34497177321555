import { useRecoilState } from "recoil";
import { BookServiceAtom } from "../../../atoms/bookServiceAtom";

export default function UserDetails() {

  const [bookService, setBookService] = useRecoilState(BookServiceAtom);

  const handleNameChange = (event: any) => {
    const name = event.target.value
    setBookService({ ...bookService, user_name: name });
  }

  const handleEmailChange = (event: any) => {
    const email = event.target.value
    setBookService({ ...bookService, user_email: email });
  }

  const handlePhoneChange = (event: any) => {
    const name = event.target.value
    setBookService({ ...bookService, user_phone: name });
  }


  return (
    <>
      <div className='mt-6 block p-6  bg-white rounded-lg border border-gray-200 shadow-md  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700'>

      <h2 className="text-lg mb-2 font-semibold text-gray-700 capitalize dark:text-white">User Details</h2>
        <div className=" mt-4">
          <div>
            <label className="text-gray-700 dark:text-gray-200" htmlFor="username">Name</label>
            <input id="name" onChange={handleNameChange} placeholder='Your Name' type="text" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" />
          </div>

          <div className='mt-1'>
            <label className="text-gray-700 dark:text-gray-200" htmlFor="emailAddress">Email</label>
            <input id="email"  onChange={handleEmailChange}  placeholder='Your Email' type="email" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" />
          </div>
          <div className='mt-1'>
            <label className="text-gray-700 dark:text-gray-200" htmlFor="emailAddress">Phone</label>
            <input id="phone"  onChange={handlePhoneChange}  placeholder='Your Phone' type="number" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" />
          </div>


        </div>
      </div>
    </>
  );
}

