import React from 'react';
import FirstHero from './components/firstHero';
import Benefits from './components/benefits';
import Testimonials from './components/testimonials';
import BlogSection from './components/blogSection';
import Base from '../base';
import Services from './components/services';
import { SearchSection } from './components/topSearchSection';
import { Steps } from '../steps/step';
import WhyChooseUs from './components/whyChooseUs';


export default function Home() {
 
  return (
    <Base>
      <div className="HomePage">
        <SearchSection/>
        <div className="mx-auto md:px-16 sm:px-8 px-4">
          <FirstHero />
          {/* <Benefits /> */}
          <Steps/>
          <Services/>
          <WhyChooseUs/>
          <Testimonials />
          {/* <BlogSection /> */}
        </div>
      </div>
    </Base>
  );
}
