import './App.tsx';
import Home from './pages/home/home';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Contact from './pages/contact/contact';
import Shop from './pages/shop/shop';
import About from './pages/about/about';
import ScrollToTop from './components/scrollToTop';
//import Terms from './pages/terms/terms';
import Privacy from './pages/privacy/privacy';
import {useEffect} from 'react';
import axios from 'axios';
import SnackbarProvider from 'react-simple-snackbar'


import {useRecoilState} from 'recoil';
import { headerServicesPopupState, homeSearchServicesPopupState } from './atoms/searchSiteAtoms';
import Apply from './pages/apply/apply';
import Login from './pages/auth/login';
import Register from './pages/auth/register';
import ForgotPassword from './pages/auth/forgotPassword';
import ResetPassword from './pages/auth/resetPassword';
import ApplyStepOne from './pages/apply/step1';
import ApplyStepTwo from './pages/apply/step2';
import BookService from './pages/book_service/bookService';
import Services from './pages/home/components/services';
import QMServices from './pages/services/services';


axios.defaults.withCredentials  = true;

function App() {

  const [headerServPopup, setHeaderServPopup] = useRecoilState(headerServicesPopupState);
  const [homeServPopup, setHomeServPopup] = useRecoilState(homeSearchServicesPopupState);

  useEffect(() => {

    document.title = "Quality Maids"
    
  
  })

  const untogglePopups = () => {
    if (homeServPopup){
        setHomeServPopup(false)
    }
    
    if (headerServPopup){
        setHeaderServPopup(false)
    }
  }


  
  
  return (
    <div onClick={untogglePopups}>
    <SnackbarProvider>
      <Router>
        <ScrollToTop>
        <Route exact path="/" component={Home} />
        <Route path="/contact" component={Contact} />
        <Route path="/shop" component={Shop} />
        <Route path="/about" component={About} />
        {/*<Route path="/terms" component={Terms} /> */}
        <Route path="/privacy" component={Privacy} />
        <Route path="/apply" component={Apply} />
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/apply-step-one" component={ApplyStepOne} />
        <Route path="/apply-step-two" component={ApplyStepTwo} />
        <Route path="/book-a-service" component={BookService} />
        <Route path="/services" component={QMServices} />
        
    



        </ScrollToTop>
      </Router>
      </SnackbarProvider>
    </div>
    
    
  );
}

export default App;
