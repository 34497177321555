import { Link } from "react-router-dom";

export function Popup(props: { popupHander: any; }) {

    let popupHander = props.popupHander;

    return (
      <>
        <div>
          <div className="md:flex">
            <div className="absolute m-auto top-14  z-20  w-full py-2 bg-white rounded-md shadow-xl dark:bg-gray-800">
              <a
                href="javascript:void(0)"
                onClick={popupHander}
                className="block px-4 py-3 text-sm text-gray-600 capitalize transition-colors duration-200 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
              >
                <div className="flex">
                  <div className="mr-3">
                  <svg className="w-8 h-8 relative top-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                  </div>
                  <div className="">
                    <div>
                      <Link
                        to="/book-a-service"
                        aria-label=""
                        className="inline-flex font-bold hover:text-burgundy-500 items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
                      >
                        House Keeping
                      </Link>
                    </div>
                    <div className="mr-3 pt-1">For your home cleaning services</div>
                  </div>
                </div>

                <div className="flex">
                  <div className="mr-3 pt-6">
                  <svg className="w-8 h-8 relative top-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 14l9-5-9-5-9 5 9 5z"></path><path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"></path></svg>
                  </div>
                  <div className="">
                    <Link
                      to="/book-a-service"
                      aria-label=""
                      className="inline-flex mr-3 pt-6 font-bold hover:text-burgundy-500 items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
                    >
                     Training Service
                    </Link>
                    <div className="mr-3 pt-1">For all training services</div>
                  </div>
                </div>

                <div className="flex">
                  <div className="mr-3 pt-6">
                  <svg className="w-8 h-8 relative top-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path></svg>
                  </div>
                  <div className="">
                  <Link
                      to="/book-a-service"
                      className="inline-flex mr-3  pt-6 font-bold hover:text-burgundy-500 items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">
                     Office Cleaning Services
                    </Link>
                    <div className="mr-3 pt-1">For your office cleaning services</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </>
    );
}
