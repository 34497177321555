import Base from '../base';
import ChooseService from './components/chooseService';

export default function QMServices(){
    return(
        <div >
        <Base>
        <ChooseService/>
            
    </Base>
    </div>
    )
}