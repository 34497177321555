import React from "react";
import { Link } from "react-router-dom";

export const Steps = () => {
  return (
    <div>
      <div className="items-center border-t-2 border-gray-50 ">
        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
        Looking for a Maid?
          <br className="hidden lg:inline-block" />look no more!
        </h1>
        <div className="md:flex">
          <div className="w-full md:w-1/2">
            <img
              className="object-cover object-center rounded mx-auto"
              alt="hero"
              src="https://media.istockphoto.com/photos/woman-using-spray-to-wipe-glass-desk-picture-id600416102?b=1&k=20&m=600416102&s=170667a&w=0&h=_H39DNcs-2LskgTle7ZjHIfjGWCh52gEoiZ78d896D0="
            />
          </div>
          <div>
          <p className="m-6 sm:text-xl text-center leading-relaxed">
            We give you a Quality one even! <br />
            We provide reliable, well trained
            once off, <br />
            regular and full time stay in maids. We
            understand the need to have someone you
            can trust with your home and family.
          </p>

          <div className="flex justify-center">
        <Link
          to="/book-a-service"
          className="inline-flex text-white bg-burgundy-300 border-0 py-2 px-6 focus:outline-none hover:bg-burgundy-500 rounded text-lg"
        >
          Book Now
        </Link>
      </div>
          </div>

          
        </div>
        
      </div>
  
    </div>
  );
};

/**
 * <div className=" border-t-2 border-gray-50 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-4xl md:mb-12">
        <h2 className="mb-6 font-sans leading-none tracking-wide text-gray-900 sm:text-4xl md:mx-auto">
          <span>
            Looking for a Maid? Look no more! We give you a Quality one even! We
            provide reliable well trained once off, regular and full time stay
            your home family.
          </span>
        </h2>
      </div>
    </div>
 * <p className="text-base text-gray-700 md:text-lg">
          It's easy to book a service
        </p>
      </div>
      <div className="grid gap-8 row-gap-8 lg:grid-cols-3">
        <div className="sm:text-center">
          <div className="flex text-white items-center justify-center w-24 h-24 mb-4 text-5xl font-extrabold rounded-full text-deep-purple-accent-400 bg-burgundy-100 sm:mx-auto">
            1
          </div>
          <h6 className="mb-2 font-semibold leading-5">
            Select The Home Service You Need
          </h6>
          <p className="max-w-md mb-3 text-sm text-gray-900 sm:mx-auto">
            Select one of the services from the list of the services available
          </p>
          {/*<a
            href="/"
            aria-label=""
            className="inline-flex items-center font-semibold transition-colors duration-200 text-burgundy-300 hover:text-burgundy-600"
          >
            Learn more
          </a>*}
          </div>
          <div className="sm:text-center">
            <div className="flex text-white items-center justify-center w-24 h-24 mb-4 text-5xl font-extrabold rounded-full text-deep-purple-accent-400 bg-burgundy-100 hover:bg-burgundy sm:mx-auto">
              2
            </div>
            <h6 className="mb-2 font-semibold leading-5">Book A Service</h6>
            <p className="max-w-md mb-3 text-sm text-gray-900 sm:mx-auto">
              Pick the time and the number of days you want the job to be done
            </p>
            <Link
              to="/book-a-service"
              aria-label=""
              className="inline-flex items-center font-semibold transition-colors duration-200 text-burgundy-300 hover:text-burgundy-600"
            >
              Learn more
            </Link>
          </div>
          <div className="sm:text-center">
            <div className="flex text-white items-center justify-center w-24 h-24 mb-4 text-5xl font-extrabold rounded-full text-deep-purple-accent-400 bg-burgundy-100 hover:bg-burgundy sm:mx-auto">
              3
            </div>
            <h6 className="mb-2 font-semibold leading-5">
              Manage Everything Online
            </h6>
            <p className="max-w-md mb-3 text-sm text-gray-900 sm:mx-auto">
              You will receive a notification as soon as you book a service with
              the details about the booking
            </p>
            <a
              href="/"
              aria-label=""
              className="inline-flex items-center font-semibold transition-colors duration-200 text-burgundy-300 hover:text-burgundy-600"
            >
              Learn more
            </a>
          </div> */
