import React from 'react'
import Base from '../base'
import ContactForm from './components/contactform'

export default function Contact() {
    return (
       <Base>

       
        <ContactForm/>
       </Base>
    )
}
