import { Link } from "react-router-dom";

export function HeaderServicePopup(props: { popupHander: any; }) {

    let popupHander = props.popupHander;

    return (
      <>
        <div>
          <div className="md:flex">
            <div className="absolute right-12 top-20  z-20  w-92 py-2 bg-white rounded-md shadow-xl dark:bg-gray-800">
              <Link
                to="/book-a-service"
                onClick={popupHander}
                className="block px-4 py-3 text-sm text-gray-600 capitalize transition-colors duration-200 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
              >
                <div className="flex">
                  <div className="mr-3">
                    <svg
                      className="w-8 h-8 relative top-1"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                      ></path>
                    </svg>
                  </div>
                  <div className="">
                    <div>
                      <Link
                       to="/book-a-service"
                        aria-label=""
                        className="inline-flex hover:text-burgundy-500 items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
                      >
                        House Keeping
                      </Link>
                    </div>
                    <div>Description Here</div>
                  </div>
                </div>

                <div className="flex">
                  <div className="mr-3 pt-6">
                    <svg
                      className="w-8 h-8 relative top-1"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                      ></path>
                    </svg>
                  </div>
                  <div className="">
                    <Link
                     to="/book-a-service"
                      aria-label=""
                      className="inline-flex mr-3 pt-6 hover:text-burgundy-500 items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
                    >
                     Training Service
                    </Link>
                    <div className="mr-3 pt-1">Description Here</div>
                  </div>
                </div>

                <div className="flex">
                  <div className="mr-3 pt-6">
                    <svg
                      className="w-8 h-8 relative top-1"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                      ></path>
                    </svg>
                  </div>
                  <div className="">
                  <Link
                      to="/book-a-service"
                      aria-label=""
                      className="inline-flex mr-3  pt-6 hover:text-burgundy-500 items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
                    >
                     Office Cleaning Services
                    </Link>
                    <div className="mr-3 pt-1">Description Here</div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </>
    );
}
