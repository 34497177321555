import React from 'react'
import Base from '../base'
import AboutSite from './components/aboutSite'






export default function About() {
    return (
       <div id="about">
           <Base>
            <AboutSite />
      
       </Base>
       </div>
    )
}
