import React from 'react'
import Base from '../base'


export default function Privacy() {
    return (
    <Base>
        <div id="privacy">
            <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                <div className="content wp-editor full">
                    <div className="desc clrfix">
                        <h1 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">Privacy and Cookies</h1>
                        <p className="text-align-center">For Quality-Maids, the protection of personal data is
                            of great importance. We collect, process and use your personal data within the scope of your
                            request and to provide you the best service possible. Below we would like to inform you
                            about the processing we do.</p>
                        <h2 className="font-bold text-md pt-5 pb-3">Cookies</h2>
                        <p className="text-align-center">We use “cookies”, which are text files placed on your computer,
                            to help identify your computer so we can tailor your user experience . We collect your
                            browser information to present our website to you in the preferred language. During this
                            processes we do not collect and store any personal data without your express prior consent,
                            in particular we abbreviate your IP address so that no reference to your person can be
                            established.</p>
                        <p className="text-align-center">. By using this
                            website, you consent to the processing of data about you by Google in the manner and for the
                            purposes set out above.</p>
                        <h2 className="font-bold text-md pt-5 pb-3">Personal data provided by
                                you</h2>
                        <p className="text-align-center">If you voluntarily transmit to us via contact forms on our
                            websites or via e-mail personal data such as names, email or postal address, telephone
                            number, personal messages etc., we store this data in our CMS &amp; CRM systems. We use this
                            data to provide the requested service (e.g. sending requested information). In order to
                            answer your request, depending on the region where you live, we might send your data to one
                            of our distributors for follow-up. After your consent to receive newsletters, MOSO stores
                            this data for the purpose of personalizing and making relevant content for the newsletters.
                            We store this data in our email newsletter software and CRM system.</p>
                        <p className="text-align-center">Without your express consent, your personal data will only be
                            used for advertising purposes within the strict limits provided by law. Without your express
                            consent you will not be contacted for advertising purposes by telephone. You may object to
                            the use of your personal data for advertising purposes at any time by contacting us at the
                            address given below.</p>
                        <h2 className="font-bold text-md pt-5 pb-3">External Service Providers
                        </h2>
                        <p className="text-align-center">We are partially assisted in the collection, processing and use
                            of personal data by selected service providers which, in accordance with their assignments,
                            act strictly according to our instructions and which are validated by us before commencing
                            their activities and subsequently monitored on a regular basis. In addition to Google, Inc.
                            for web analytics purposes, we share data collected with our technical website support
                            company. After your consent to receive newsletters, we share your data with MailPlus, our
                            newsletter service agency.</p>
                        
                        <h2 className="font-bold text-md pt-5 pb-3">Deletion of Data</h2>
                        <p className="text-align-center">We delete your personal data at the latest 24 months after
                            receiving it, unless we store your information for ordering processes. You may at any time
                            demand information on the data stored about you as well as the correction or deletion of
                            incorrect data. Please address your objections, requests for information or deletion to:</p>
                        <p className="text-align-center">Quality Maids<br/></p>
                        
                    </div>
                </div>
            </div>  
        </div>
    </Base>
            
    )
}
