
import {atom } from 'recoil'

export const homeSearchServicesPopupState = atom({
    key: 'homeSearchPopupState', // unique ID (with respect to other atoms/selectors)
    default: false, // default value (aka initial value)
  });

export const headerServicesPopupState = atom({
    key: 'headerSearchPopupState', // unique ID (with respect to other atoms/selectors)
    default: false, // default value (aka initial value)
  });