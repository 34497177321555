import React from 'react'
import { useRecoilState } from 'recoil'
import { BookServiceAtom } from '../../../atoms/bookServiceAtom'

export default function IndoorExtras() {


    const [bookService, setBookService] = useRecoilState(BookServiceAtom);

    const handleExtrasChange = (event: any, boxname:string) => {
        const boxvalue = event.target.checked
        let extras = bookService.extras
        let newExtras: any = {
            ...extras
        }

        newExtras[boxname] = boxvalue
        setBookService({ ...bookService, extras: newExtras })
    }

    return (
        <div className="mt-6 block p-6  bg-white rounded-lg border border-gray-200 shadow-md  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <div>
            <h2 className="text-lg mb-2 font-semibold text-gray-700 capitalize dark:text-white">Extras</h2>

         
                <div className="grid md:grid-cols-2">
                <div><input onChange={(event) => handleExtrasChange(event, "laundry_and_ironing")}  type="checkbox" className="rounded text-burgundy-300 focus:ring-burgundy-300 focus:border-burgundy-300" /> Laundry & Ironing <br /></div>
                <div><input onChange={(event) => handleExtrasChange(event, "inside_fridge")}  type="checkbox" className="rounded text-burgundy-300 focus:ring-burgundy-300 focus:border-burgundy-300" /> Inside Fridge <br /></div>
                <div><input onChange={(event) => handleExtrasChange(event, "inside_ovens")}  type="checkbox" className="rounded text-burgundy-300 focus:ring-burgundy-300 focus:border-burgundy-300" /> Inside Oven <br /></div>
                <div><input onChange={(event) => handleExtrasChange(event, "inside_cabinets")}  type="checkbox" className="rounded text-burgundy-300 focus:ring-burgundy-300 focus:border-burgundy-300" /> Inside Cabinets <br /></div>
                <div><input onChange={(event) => handleExtrasChange(event, "interior_windows")}  type="checkbox" className="rounded text-burgundy-300 focus:ring-burgundy-300 focus:border-burgundy-300" /> Interior Windows <br /></div>
                <div><input onChange={(event) => handleExtrasChange(event, "interior_walls")}  type="checkbox" className="rounded text-burgundy-300 focus:ring-burgundy-300 focus:border-burgundy-300" /> Interior Walls <br /></div>
                </div>
            </div>
        </div>
    )
}
