import React from "react";
import { Link } from "react-router-dom";

export default function firstHeroHome() {
  return (
    <div className="firstHero">
      <section className="text-gray-600 body-font">
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
              A clean home
              <br className="hidden lg:inline-block" /> is a happy home
            </h1>
            <p className="mb-8 leading-relaxed">
              Cleaning services are indispensable, not only for the looks but
              also for the sake of hygiene. This applies to both homes and
              office spaces, because while it is essential to maintain
              cleanliness at home, a healthy workspace environment is equally
              important. And why not let professionals take care of such ? Our
              company would help clean off the grime and dust in the various
              places of your properties which are hard to reach. We, at Quality
              Maids offer extensive cleaning services at reasonable rates.
            </p>
            <div className="flex justify-center">
              {/*<button  className="inline-flex text-white bg-burgundy-300 border-0 py-2 px-6 focus:outline-none hover:bg-burgundy-500 rounded text-lg">
                Book Now
              </button>*/}
              <Link
                to="/book-a-service"
                className="inline-flex text-white bg-burgundy-300 border-0 py-2 px-6 focus:outline-none hover:bg-burgundy-500 rounded text-lg"
              >
                Book Now
              </Link>
              {/* <button className="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">Button</button>*/}
            </div>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
            <img
              className="object-cover object-center rounded"
              alt="hero"
              src="https://images.unsplash.com/photo-1527515637462-cff94eecc1ac?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
            />
          </div>
        </div>
      </section>
    </div>
  );
}
